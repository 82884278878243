<template>
  <div class="page-header clear-filter" filter-color="">
    <div class="page-header-image" style="background-image: url('img/login1.png')"></div>
    <div class="content-center" style="">
      <alert v-if="showAlert" ref="alert" :type="showAlertType" dismissible @dismiss="showAlert = false">
        {{ showAlertContext }}
      </alert>
      <div class="container">
        <div class="col-md-5 ml-auto mr-auto">
          <card class="card-signup" header-classes="text-center" color="orange">
            <template slot="header">
              <img v-lazy="'favicon.png'" alt="" style="max-width:50px;" />
              <h3 class="card-title title-up">{{ $t('signup.Login') }}</h3>
              <h5 class="card-title" style="margin-bottom:0px;">{{ $t('signup.LoginTips') }}</h5>
            </template>
            <template>
              <form @submit.prevent="checkForm">
                <fg-input type="email" class="no-border input-lg" addon-left-icon="now-ui-icons ui-1_email-85"
                  :placeholder="$t('signup.Email')" v-model="email" name="email" v-validate="'required|email'"
                  ref="email">
                </fg-input>
                <span v-show="errors.has('email')" class="text-neutral">{{ errors.first('email') }}</span>
                <fg-input type="password" class="no-border input-lg" addon-left-icon="now-ui-icons ui-1_lock-circle-open"
                  :placeholder="$t('signup.Password')" v-model="password" name="password" v-validate="'required|min:6'"
                  ref="password">
                </fg-input>
                <span v-show="errors.has('password')" class="text-neutral">{{ errors.first('password') }}</span>
                <div class="pull-right" style="margin-bottom: 15px;">
                  <router-link :to="{ path: '/resetPassword' }" class="link footer-link" style="font-size: small;">{{
                    $t('signup.forgotPassword') }}</router-link>
                </div>
                <button type="submit" class="btn btn-neutral btn-round btn-lg btn-block">{{ $t('signup.Login') }}</button>
                <div class="pull-left">
                  <h6>
                    <router-link :to="{ path: '/regist' }" class="link footer-link">{{ $t('signup.CreateAccount')
                    }}</router-link>
                  </h6>
                </div>
              </form>
            </template>
          </card>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Button, FormGroupInput, Alert } from '@/components';
export default {
  name: 'login-page',
  bodyClass: 'login-page',
  components: {
    Card,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    Alert
  },
  data() {
    return {
      email: '',
      password: '',
      showAlert: false,
      showAlertType: 'success',
      showAlertContext: '',
      clientType: 0
    };
  },
  methods: {
    checkForm() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.login();
        }
      });
    },
    async login() {
      // Handle login logic here
      try {
        const response = await this.axios.post('/login/loginComplete', {
          email: this.email,
          password: this.password,
          clientType: this.clientType
        });
        if (response.data.success) {
          // Handle successful login, e.g., redirect to dashboard
          var token = response.data['data']['token'];
          var userId = response.data['data']['userId'];
          var vipType = response.data['data']['vipType'];
          if (token == null || token == undefined) {
            this.showAlertType = 'danger';
            this.showAlertContext = response.data.msg;
            this.showAlert = true;
          } else {
            localStorage.setItem('ga-user-token', token);
            localStorage.setItem('ga-user-id', userId);
            localStorage.setItem('ga-user-vipType', vipType);
            // this.$cookies.set('token', token, "7D");
            this.showAlertType = 'success';
            this.showAlertContext = response.data.msg;
            this.showAlert = true;
            this.$router.push('/');
            this.$router.go(0);
          }

        } else {
          // Handle error message from server
          this.showAlertType = 'danger';
          this.showAlertContext = response.data.msg;
          this.showAlert = true;
        }
      } catch (error) {
        // Handle network or other errors
        console.error(error);
        this.showAlertType = 'danger';
        this.showAlertContext = this.$t('signup.loginError');
        this.showAlert = true;
      }
    },
    getUrlParam(key) {
      var url = window.location.href; //获取当前url
      var cs = url.split("?")[1]; //获取?之后的参数字符串
      var cs_arr = cs.split("&"); //参数字符串分割为数组

      for (var i = 0; i < cs_arr.length; i++) {
        var param_i = cs_arr[i].split("=");
        if (param_i[0] == key) {
          return param_i[1];
        }
      }
      return "";
    }
  }
};
</script>
<style></style>
