<template>
  <div class="image-detail">
    <div class="detail-header">
      <h2>{{ shareDetail.title }}</h2>
      <el-button class="transparent-button" round size="mini" @click="toggleFavorite">
        <i
          :class="[shareDetail.favorite === 0 ? 'iconfont ga-icon-favorite' : 'iconfont ga-icon-favorite-fill', 'custom-icon']">

        </i>
      </el-button>
    </div>
    <el-carousel>
      <el-carousel-item v-for="item in shareDetail.imagesDtos" :key="item.id">
        <div class="image-container">
          <img :src="item.url" :alt="item.alt" class="image-section" />
          <el-button icon="el-icon-download" circle @click="download(item.url)" class="download-button"></el-button>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="tags-section">
      <el-tag v-for="tag in shareDetail.tagsDtos" :key="tag.id" size="small" class="tags-item">{{ tag.name }}</el-tag>
    </div>
    <div class="info-section">
      <h3>Prompt</h3>
      <p>{{ shareDetail.imagesDtos[0].prompt }}</p>
      <h3>Negative Prompt</h3>
      <p>{{ shareDetail.imagesDtos[0].negPrompt }}</p>
    </div>
    <div class="info-section">
      <h3>Full infos</h3>
      <p>{{ shareDetail.imagesDtos[0].config['infotexts'][0] }}</p>
    </div>
    <!-- <div class="related-models">
      <h3>Models</h3>
      <div v-for="model in models" :key="model.name" class="model-item">
        <el-tag>{{ model.name }}</el-tag>
      </div>
    </div>
    <div class="comments-section">
      <h3>评论</h3>
      <div v-for="comment in comments" :key="comment.id" class="comment-item">
        <p><strong>{{ comment.user }}:</strong> {{ comment.text }}</p>
        <p class="comment-date">{{ comment.date }}</p>
      </div>
    </div> -->
  </div>
</template>

<script>
import { Button, Tag, Carousel, CarouselItem, Message } from 'element-ui';

export default {
  components: {
    [Button.name]: Button,
    [Tag.name]: Tag,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
  },
  data() {
    return {
      models: [
        { name: "majicMIX realistic" },
        { name: "LEOSAM's Instant photo" },
      ],
      comments: [
        { id: 1, user: "老假", text: "这种方法选择这个好吗?", date: "2024/05/24 17:13:04" },
        { id: 2, user: "小张", text: "很好，谢谢分享", date: "2024/05/24 18:13:04" },
      ],
      shareDetail: {
        imagesDtos: [{
          prompt: null,
          negPrompt: null,
          config: {
            infotexts: []
          }
        }]
      },
      shareId: null
    };
  },
  methods: {
    download(url) {
      const link = document.createElement('a');
      link.href = url;
      link.download = url.substring(url.lastIndexOf('/') + 1);
      link.click();
    },
    async getShareDetail(shareId) {
      try {
        this.shareId = shareId;
        const response = await this.axios.get('/share/getShareDetail', { params: { shareId: shareId } });
        if (response.data.success) {
          this.shareDetail = response.data.data;
        } else {
          console.error(response.data.msg);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async toggleFavorite() {
      this.shareDetail.favorite = this.shareDetail.favorite === 0 ? 1 : 0;
      // 在此处添加任何其他逻辑，例如将收藏状态发送到服务器
      try {
        const response = await this.axios.get('/userImages/toggleFavorite', { params: { shareId: this.shareId, favorite: this.shareDetail.favorite } });
        if (response.data.success) {
          Message({
            message: response.data.msg,
            type: 'success'
          });
        } else {
          Message({
            message: response.data.msg,
            type: 'success'
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  created() {
  }
};
</script>

<style scoped>
.image-detail {
  max-width: 800px;
  margin: 20px auto;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.image-container {
  position: relative;
  width: 100%;
  height: 300px;
}

.image-section {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.download-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
}

.info-section {
  margin-top: 20px;
}

.tags-section {
  margin-top: 20px;
}

.tags-item {
  margin-right: 10px;
}

.related-models {
  margin-top: 20px;
}

.model-item {
  margin-top: 10px;
}

.comments-section {
  margin-top: 20px;
}

.comment-item {
  border-top: 1px solid #eee;
  padding-top: 10px;
  margin-top: 10px;
}

.comment-date {
  color: #999;
  font-size: 0.8em;
}
</style>
