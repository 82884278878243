<template>
    <div>
        <vue-waterfall-easy ref="shareImagesArr" :imgsArr="shareImagesArr" :maxCols="4" :height="shareImagesArrHeight"
            @scrollReachBottom="findShareByPage" @click="clickImg">
            <div slot="waterfall-head">
                <div id="share">
                    <div class="banner">
                        <img src="https://imgs-f20c.obs.ap-southeast-3.myhuaweicloud.com/Cloud%20SD%20postor.png"
                            alt="Banner Image" />
                    </div>
                    <div class="categories">
                        <div class="category-buttons">
                            <el-button v-for="category in categories" :key="category.name" type="text"
                                class="home-nav-button" :class="{ active: category.name === activeCategory }"
                                @click="setActiveCategory(category.name)">
                                {{ category.name }}
                            </el-button>
                        </div>
                        <div class="sort-buttons">
                            <el-button v-for="sortOption in sortOptions" :key="sortOption" type="text"
                                class="home-nav-button" :class="{ active: sortOption === activeSort }"
                                @click="setActiveSort(sortOption)">
                                {{ sortOption }}
                            </el-button>
                        </div>
                    </div>
                </div>
            </div>
            <div slot-scope="props" class="img-info" style="position:absolute; left: 15px;bottom:15px;">
                <el-button class="transparent-button" round size="mini" @click="toggleFavorite(props.value)">
                    <i
                        :class="[props.value.favorite === 0 ? 'iconfont ga-icon-favorite' : 'iconfont ga-icon-favorite-fill', 'custom-icon']">
                        {{ props.value.favoriteCount }}
                    </i>
                </el-button>
            </div>
            <div slot="waterfall-over">{{ $t('waterfall.over') }}</div>
        </vue-waterfall-easy>
        <el-dialog :title="$t('shareDetail.detail')" :visible.sync="shareDetail.dialogShow"
            @opened="$refs.shareDetail.getShareDetail(shareDetail.share.id)">
            <shareDetail ref="shareDetail"></shareDetail>
        </el-dialog>
        <goShare></goShare>
    </div>
</template>
  
<script>
import { Button, Dialog, Message } from 'element-ui';
import vueWaterfallEasy from 'vue-waterfall-easy';
import shareDetail from './ShareDetail.vue';
import goShare from './GoShare.vue';

export default {
    components: {
        [Button.name]: Button,
        [Dialog.name]: Dialog,
        vueWaterfallEasy,
        shareDetail,
        goShare
    },
    data() {
        return {
            categories: [{ "name": 'All', "index": 0 }],
            activeCategory: 'All',
            sortOptions: ['Latest', 'Hottest'],
            activeSort: 'Latest',
            shareImagesArr: [],
            nextPageIndex: 0,
            shareImagesArrHeight: window.innerHeight + 'px',
            shareDetail: {
                dialogShow: false,
                share: {}
            }

        };
    },
    methods: {
        setActiveSort(sortOption) {
            this.activeSort = sortOption;
            this.firstLoadPage();
        },
        setActiveCategory(category) {
            this.activeCategory = category;
            this.firstLoadPage();
        },
        firstLoadPage() {
            this.shareImagesArr = [];
            this.nextPageIndex = 0;
            this.findShareByPage();
        },
        async findShareByPage() {
            try {
                var tagsId = null;
                this.categories.forEach(cate => {
                    if (this.activeCategory === cate['name']) {
                        tagsId = cate['id'];
                    }
                });
                var requestData = { 'pageNum': this.nextPageIndex, 'sortType': this.activeSort, 'tagsId': tagsId };
                const response = await this.axios.post('/share/findShareByPage', requestData);
                if (response.data.success) {
                    var totalPages = response.data['data']['totalPages'];
                    var datas = response.data['data']['datas'];
                    if (datas !== null && datas.length > 0) {
                        var imgs = [];
                        datas.forEach(data => {
                            imgs.push({ 'src': data['coverObjectUrl'], "id": data['id'], "coverId": data['coverId'], "favorite": data['favorite'], "favoriteCount": data['favoriteCount'] });

                        });
                        this.shareImagesArr = this.shareImagesArr.concat(imgs);
                        this.nextPageIndex++;
                    } else {
                        if (this.nextPageIndex >= totalPages) {
                            this.$refs.shareImagesArr.waterfallOver();
                        }
                    }

                } else {
                    console.error(response.data['data']['msg']);
                }
            } catch (error) {
                console.error(error);
            }

        },
        async findAllTags() {
            try {
                const response = await this.axios.get('/share/allTags');
                if (response.data.success) {
                    var data = response.data['data'];
                    if (data !== null && data.length > 0) {
                        var tags = [];
                        data.forEach(tag => {
                            tags.push({ 'id': tag['id'], "name": tag['name'], "index": tag['index'] });
                        });
                        this.categories = this.categories.concat(tags);
                    }

                } else {
                    console.error(response.data['msg']);
                }
            } catch (error) {
                console.error(error);
            }
        },
        async toggleFavorite(share) {

            if (share.favorite === 0) {
                share.favorite = 1;
                share.favoriteCount++;
            } else {
                share.favorite = 0;
                share.favoriteCount--;
            }
            try {
                const response = await this.axios.get('/userImages/toggleFavorite', { params: { shareId: share.id, favorite: share.favorite } });
                if (response.data.success) {
                    Message({
                        message: response.data.msg,
                        type: 'success'
                    });
                } else {
                    Message({
                        message: response.data.msg,
                        type: 'success'
                    });
                }
            } catch (error) {
                console.error(error);
            }
        },
        clickImg(event, { index, value }) {
            // 阻止a标签跳转
            event.preventDefault()
            // 只有当点击到图片时才进行操作
            if (event.target.tagName.toLowerCase() == 'img') {
                this.shareDetail.dialogShow = true;
                this.shareDetail.share = value;
            }
        }
    },
    created() {
        // UI渲染前执行
        this.findAllTags();
        this.firstLoadPage();
    }

};
</script>
  
<style>
body {
    margin: 0;
    font-family: Arial, sans-serif;
}

#share {
    max-width: 1200px;
    margin: 53px auto 0 auto;
    padding-top: 20px;
    padding: 20px;
}

.banner img {
    width: 100%;
    height: auto;
    display: block;
}

.categories {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
}

.category-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-bottom: 5px;
}

.sort-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    width: 100%;
}
</style>
