<template>
    <div class="upload-modal">
        <form data-vv-scope="shareImageForm">
            <div class="images-preview">
                <div v-for="(image, index) in shareImageForm.images" :key="index" class="image-container">
                    <img :src="image.src" :alt="image.alt" @click="clickImage(index)" />
                    <n-button class="btn-neutral" v-show="image.cover" :style="{ right: '40px' }">✏️</n-button>
                    <n-button class="btn-neutral" @click="deleteImage(index)">🗑️</n-button>
                </div>
                <span v-show="shareImageForm.imageValidator" class="text-neutral">{{
                    shareImageForm.imageValidator }}</span>
            </div>
            <div class="form-section">
                <el-input v-model="shareImageForm.title" :placeholder="$t('imageShare.title')" maxlength="30"
                    show-word-limit />
                <el-input v-model="shareImageForm.descript" type="textarea" :placeholder="$t('imageShare.desc')"
                    maxlength="200" show-word-limit></el-input>
                <el-select v-model="shareImageForm.tagsIds" multiple :multiple-limit="3"
                    :placeholder="$t('imageShare.tags')" v-validate="'required'" name="tag">
                    <el-option v-for="item in shareImageForm.tagOptions" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
                <span v-show="errors.has('shareImageForm.tag')" class="text-neutral">{{
                    errors.first('shareImageForm.tag')
                }}</span>
            </div>
            <div class="publish-section">
                <n-button class="btn-primary" @click="submitValidator('shareImageForm')">{{ $t('imageShare.publish')
                }}</n-button>
                <n-button v-show="type === 'edit'" class="btn-neutral" @click="deleteShare()">{{
                    $t('imageShare.delete')
                }}</n-button>
            </div>
        </form>
    </div>
</template>
  
<script>
import { Button } from '@/components';
import { Select, Option, Input, Message, MessageBox } from 'element-ui';

export default {
    components: {
        [Button.name]: Button,
        [Select.name]: Select,
        [Option.name]: Option,
        [Input.name]: Input,
    },
    props: ['type'],
    data() {
        return {
            shareId: null,
            shareImageForm: {
                title: '',
                descript: '',
                tagsIds: [],
                tagOptions: [],
                imageValidator: '',
                images: [],
                coverId: null
            }
        };
    },
    methods: {
        clickImage(index) {
            this.shareImageForm.images.forEach(image => {
                image['cover'] = false;
            });
            this.shareImageForm.images[index]['cover'] = true;
            this.shareImageForm.coverId = this.shareImageForm.images[index].id;
            this.$forceUpdate();
        },
        deleteImage(index) {
            if (this.shareImageForm.images.length === 1) {
                Message({
                    message: this.$t('imageShare.minPics'),
                    type: 'warning'
                });
                return;
            }
            var indexCover = this.shareImageForm.images[index]['cover'];
            this.shareImageForm.images.splice(index, 1);
            if (indexCover) {
                this.shareImageForm.images[0]['cover'] = true;
                this.shareImageForm.coverId = this.shareImageForm.images[0].id;
            }
        },
        submitValidator(formName) {
            // 实现发布逻辑
            this.$validator.validateAll(formName).then((isValid) => {
                if (this.shareImageForm.images.length === 0) {
                    this.shareImageForm.imageValidator = this.$t('imageShare.minPics');
                    return false;
                } else if (this.shareImageForm.images.length > 6) {
                    this.shareImageForm.imageValidator = this.$t('imageShare.maxPics');
                    return false;
                }

                if (isValid) {
                    this.publishShare();
                }
            });
        },
        async publishShare() {
            try {
                var userImageIds = [];
                this.shareImageForm.images.forEach(img => {
                    userImageIds.push(img['id']);
                });

                var requestData = {
                    'shareId': this.shareId, 'userImageIds': userImageIds, 'title': this.shareImageForm.title,
                    'descript': this.shareImageForm.descript, 'tagIds': this.shareImageForm.tagsIds, 'coverId': this.shareImageForm.coverId
                };
                const response = await this.axios.post('/userImages/shareImages', requestData);
                if (response.data.success) {
                    var data = response.data['data'];
                    if (data !== null) {
                        this.$emit('shareImagesSuccess', response.data['msg']);
                    }

                } else {
                    Message({
                        message: response.data['msg'],
                        type: 'warning'
                    });
                }
            } catch (error) {
                console.error(error);
            }
        },
        async findAllTags() {
            try {
                const response = await this.axios.get('/share/allTags');
                if (response.data.success) {
                    var data = response.data['data'];
                    if (data !== null && data.length > 0) {
                        var tags = [];
                        data.forEach(tag => {
                            tags.push({ 'id': tag['id'], "name": tag['name'], "index": tag['index'] });
                        });
                        this.shareImageForm.tagOptions = tags;
                    }

                } else {
                    Message({
                        message: response.data['msg'],
                        type: 'warning'
                    });
                }
            } catch (error) {
                console.error(error);
            }
        },
        openImageShare(images, shareId) {
            this.shareId = null;
            this.shareImageForm['title'] = '';
            this.shareImageForm['descript'] = '';
            this.shareImageForm['tagsIds'] = [];
            if (this.type === 'add') {
                this.shareImageForm.images = images;
                this.shareImageForm.images.forEach(image => {
                    image['cover'] = false;
                });
                this.shareImageForm.images[0]['cover'] = true;
                this.shareImageForm.coverId = this.shareImageForm.images[0].id;
            } else if (this.type === 'edit') {
                this.shareId = shareId;
                this.getShareDetail();
            }
        },
        async getShareDetail() {
            try {
                const response = await this.axios.get('/share/getShareDetail', { params: { shareId: this.shareId } });
                if (response.data.success) {
                    var shareDetail = response.data.data;
                    var imgs = [];
                    shareDetail.imagesDtos.forEach(data => {
                        var cover = false;
                        if (data['id'] === shareDetail.coverId) {
                            cover = true;
                        } else {
                            cover = false;
                        }
                        imgs.push({ 'src': data['url'], "id": data['id'], 'cover': cover });
                    });
                    this.shareImageForm.images = imgs;
                    this.shareImageForm['title'] = shareDetail.title;
                    this.shareImageForm['descript'] = shareDetail.descript;
                    this.shareImageForm['coverId'] = shareDetail.coverId;
                    shareDetail.tagsDtos.forEach(tag => {
                        this.shareImageForm['tagsIds'].push(tag.id);
                    });
                } else {
                    Message({
                        message: response.data['msg'],
                        type: 'warning'
                    });
                }
            } catch (error) {
                console.error(error);
            }
        },
        deleteShare() {
            MessageBox.confirm(this.$t('imageShare.deleteMessage'), 'Confirm', {
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                this.deleteSharePublish();
            });
        },
        async deleteSharePublish() {
            try {
                const response = await this.axios.get('/userImages/deleteShareById', { params: { shareId: this.shareId } });
                if (response.data.success) {
                    this.$emit('shareImagesSuccess', response.data['msg']);
                } else {
                    Message({
                        message: response.data['msg'],
                        type: 'warning'
                    });
                }
            } catch (error) {
                console.error(error);
            }
        }
    },
    created() {
        //UI渲染前执行
        this.findAllTags();
    }
};
</script>
  
<style scoped>
.upload-modal {
    max-width: 600px;
    margin: 20px auto;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.upload-section {
    text-align: center;
    margin-bottom: 20px;
}

.images-preview {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;
}

.image-container {
    position: relative;
    width: calc(50% - 5px);
}

.image-container img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.image-container .btn-neutral {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.form-section {
    margin-bottom: 20px;
}

.form-section .el-input,
.form-section .el-textarea,
.form-section .el-select {
    margin-bottom: 10px;
    width: 100%;
}

/* /deep/ .el-input .el-input__inner,
/deep/ .el-textarea .el-input__inner,
/deep/ .el-select.is-focus .el-input__inner {
    border-color: #e76d42 !important;
} */

.publish-section {
    text-align: center;
}

.publish-section .btn-primary,
.publish-section .btn-neutral {
    width: 100%;
}
</style>
  