module.exports = {
    nav: {
        times: "waktu",
        Beta: "Beta",
    },
    paint: {
        title: "Melukis AI",
        desc: "Platform melukis AI cloud terkemuka",
        painting: "Melukis",
    },
    e_learn: {
        title: "Belajar Daring",
        desc: "Platform belajar daring resmi dari Genius academy",
    },
    chatgpt_x: {
        title: "ChatGPT-X",
        desc: "Bangun asisten AI Anda sendiri",
    },
    signup: {
        title: "Daftar",
        UpdatePassword: "Perbarui Kata Sandi",
        Password: "Kata Sandi",
        NewPassword: "Kata Sandi Baru",
        NewPasswordAgain: "Ulang Kata Sandi Baru",
        Logout: "Keluar",
        Login: "Masuk",
        Email: "Email",
        CreateAccount: "Buat Akun",
        SignUp: "Daftar",
        VerifyCode: "Kode Verifikasi",
        GetVerifyCode: "Dapatkan Kode Verifikasi",
        PasswordAgain: "Kata Sandi, Lagi",
        EnterPhoneNumber: "Masukkan nomor telepon",
        EnterLisence: "Enter lisence",
        sendVerifyCodeError: "Terjadi kesalahan saat mengirim Kode Verifikasi. Silakan coba lagi.",
        registError: "Terjadi kesalahan saat mendaftar. Silakan coba lagi.",
        loginError: "Terjadi kesalahan saat masuk. Silakan coba lagi.",
        forgotPassword: "Lupa kata sandi?",
        ResetPassword: "Atur ulang kata sandi",
        Reset: "Atur ulang",
        ResetError: "Terjadi kesalahan saat mengatur ulang kata sandi. Silakan coba lagi.",
        LoginTips: "Dapat langsung masuk menggunakan akun platform ChatGPT-X"
    },
    button: {
        Submit: "Kirim",
        GoWhatsapp: "Open Whatsapp",
    },
    redeemcode: {
        UseRedeemCode: "Gunakan Kode Redeem",
        RedeemCode: "Kode Redeem",
        GetRedeemCode: "Dapatkan Kode Redeem",
    },
    profile: {
        myInfo: "Informasi saya",
        myImages: "Gambar saya",
        myShares: "Bagikan saya",
        myDetails: "Detail saya",
        userName: "Nama pengguna",
        email: "Email",
        memberLevel: "Tingkat anggota",
        memberExpireDate: "Tanggal Kedaluwarsa Anggota",
        likeCount: "Suka",
        favoriteCount: "Favorit",
        updateAvatar: "Ubah avatar",
        uploadAvatarFile: "Pilih avatar"
    },
    waterfall: {
        over: "Semua ada di sini"
    },
    myImages: {
        choiceShare: "Pilih",
        cancelChoice: "Batal",
        share: "Bagikan",
        delete: "Hapus",
        deleteMessage: "Anda akan menghapus file-file ini, lanjutkan?",
        selectMessage: "Harap pilih gambar",

    },
    imageDetail: {
        detail: "Detail gambar",
    },
    imageShare: {
        title: "Judul berbagi",
        desc: "Deskripsi",
        tags: "Tag",
        publish: "Terbitkan",
        delete: "Hapus",
        minPics: "Pilih setidaknya satu gambar",
        maxPics: "Pilih maksimal enam gambar",
        deleteMessage: "Anda akan menghapus berbagi ini, lanjutkan?",
        gotoShare: "Pergi ke bagikan",
    },
    shareDetail: {
        detail: "Detail berbagi",
    },
    Recharge: {
        Name: "Isi ulang",
        ModalTitle: "Isi ulang",
        ToWhatsapp: "Pergi ke Whatsapp",
        RechargeTitle: {
            nowRecharge: "Sekarang isi ulang ",
            ToGet: " dapat memperoleh ",
            Package: " 200 kali/hari/bulan ",
            OriginalPrice: "harga asli ",
            ToBuyByWhatsapp:
                "Hubungi Layanan Pelanggan melalui Whatsapp untuk membeli kode penebusan: ",
        },
    },
}