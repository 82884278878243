<template>
    <div class="user-profile">
        <div class="sidebar">
            <div class="profile-info">
                <n-button @click.native="modals.updateAvatar = true" type="neutral" round
                    :style="{ padding: '0px 0px', backgroundColor: '#f7f7f7', width: '100px' }">
                    <img :src="currentUser.avatar" alt="User Avatar" class="rounded-circle img-raised" />
                </n-button>

                <h2 class="username">{{ currentUser.name }}</h2>
            </div>
            <div class="nav-buttons">
                <n-button type="neutral" block @click="setActiveTab('details')">{{ $t('profile.myInfo') }}</n-button>
                <n-button type="neutral" block @click="setActiveTab('myImagesTab')">{{ $t('profile.myImages') }}</n-button>
                <n-button type="neutral" block @click="setActiveTab('mySharesTab')">{{ $t('profile.myShares') }}</n-button>
            </div>
        </div>
        <div class="content">
            <div v-if="activeTab === 'details'">
                <h3>{{ $t('profile.myDetails') }}</h3>
                <div class="details">
                    <div class="detail-item">
                        <strong>{{ $t('profile.userName') }}:</strong> {{ currentUser.name }}
                    </div>
                    <div class="detail-item">
                        <strong>{{ $t('profile.email') }}:</strong> {{ currentUser.email }}
                    </div>
                    <div class="detail-item">
                        <strong>{{ $t('profile.memberLevel') }}:</strong> {{ currentUser.vipTypeName }}
                    </div>
                    <div class="detail-item">
                        <strong>{{ $t('profile.memberExpireDate') }}:</strong> {{ currentUser.vipExpireDateStr }}
                    </div>
                </div>

            </div>
            <div v-if="activeTab === 'myImagesTab'">
                <div class="details">
                    <div class="detail-item-post">
                        <n-button type="primary" round @click="choiceImages()"
                            :style="{ margin: '0px', padding: '4px 20px 4px 20px ' }">
                            <strong>{{ myImagesChoosing ? $t('myImages.cancelChoice') : $t('myImages.choiceShare')
                            }}</strong></n-button>
                    </div>
                    <div class="detail-item-post">
                        <n-button v-show="myImagesChoosing" type="success" round @click="showShareImages()"
                            :style="{ margin: '0px', padding: '4px 20px 4px 20px ' }">
                            {{ $t('myImages.share') }}</n-button>
                    </div>
                    <div class="detail-item-post">
                        <n-button v-show="myImagesChoosing" type="warning" round @click="deleteImages()"
                            :style="{ margin: '0px', padding: '4px 20px 4px 20px ' }">
                            {{ $t('myImages.delete') }}</n-button>

                    </div>
                </div>
                <hr class="divider" />
                <vue-waterfall-easy ref="myImagesWaterfall" :imgsArr="myImagesArr" :maxCols="4" :height="imgsArrHeight"
                    @scrollReachBottom="findImagesByPage" @click="clickMyImage">
                    <div class="img-info" slot-scope="props">
                        <n-checkbox v-show="myImagesChoosing" v-model="props.value.selected" class="custom-checkbox" />
                    </div>
                    <div slot="waterfall-over">{{ $t('waterfall.over') }}</div>
                </vue-waterfall-easy>
            </div>
            <div v-if="activeTab === 'mySharesTab'">
                <div class="details">
                    <!-- <div class="detail-item-post">
                        <strong>{{ $t('profile.likeCount') }}:</strong> {{ dataCount.like }}
                    </div> -->
                    <div class="detail-item-post">
                        <strong>{{ $t('profile.favoriteCount') }}:</strong> {{ currentUser.favoriteCount }}
                    </div>
                </div>
                <hr class="divider" />
                <vue-waterfall-easy ref="mySharesWaterfall" :imgsArr="share.mySharesImages" :maxCols="4"
                    :height="imgsArrHeight" @scrollReachBottom="findShareImagesByPage" @click="clickMyShareImage">
                    <div class="img-info" slot-scope="props">
                        <i :class="['iconfont ga-icon-favorite-fill', 'custom-icon']">
                            {{ props.value.favoriteCount }}
                        </i>
                        <el-tag v-show="props.value.isPublish != 1"
                            :type="props.value.isPublish === 0 ? 'success' : 'warning'" size="mini" effect="plain"
                            color="rgba(0, 0, 0, 0.2)" style="border: none; margin-left: 10px;">{{
                                props.value.isPublishName }}</el-tag>
                    </div>
                    <div slot="waterfall-over">{{ $t('waterfall.over') }}</div>
                </vue-waterfall-easy>

            </div>
        </div>
        <el-dialog :title="$t('profile.updateAvatar')" :visible.sync="modals.updateAvatar">
            <n-button type="primary" round @click="triggerAvatarFile">
                {{ $t('profile.uploadAvatarFile') }}
            </n-button>
            <input type="file" ref="avatarFile" @change="handleAvatarFileChange" style="display: none"
                accept=".png,.jpg,.JPG,.jpeg" />
            <span v-show="errors.has('avatarFile')" class="text-neutral">{{ errors.first('avatarFile') }}</span>

            <template slot="footer">
                <span :class="modals.updateAvatarMsgClass" :style="{ visibility: '{updateAvatarMsgVisib}' }">
                    {{ modals.updateAvatarMsg }}
                </span>
                <n-button type="danger" @click.native="handleAvatarFileSubmit">{{ $t('button.Submit') }}</n-button>
            </template>
        </el-dialog>
        <el-dialog :title="$t('myImages.share')" :visible.sync="share.modal"
            @opened="$refs.imageShare.openImageShare(share.images, share.shareId)">
            <imageShare ref="imageShare" :type="share.type" @shareImagesSuccess="shareImagesSuccess">
            </imageShare>
        </el-dialog>
        <el-dialog :title="$t('imageDetail.detail')" :visible.sync="imageDetail.modal">
            <imageDetail :image="imageDetail.image"></imageDetail>
        </el-dialog>
    </div>
</template>
  
<script>
import { Button, FormGroupInput, Switch, Checkbox } from '@/components';
import vueWaterfallEasy from 'vue-waterfall-easy';
import imageShare from './share/ImageShare.vue';
import imageDetail from './ImageDetail.vue';
import { Message, Dialog, MessageBox, Tag } from 'element-ui';
export default {
    components: {
        [Button.name]: Button,
        [FormGroupInput.name]: FormGroupInput,
        vueWaterfallEasy,
        [Switch.name]: Switch,
        [Checkbox.name]: Checkbox,
        imageShare,
        imageDetail,
        [Dialog.name]: Dialog,
        [Tag.name]: Tag,
    },
    data() {
        return {
            activeTab: 'details',
            currentUser: {},
            modals: {
                updateProfile: false,
                updateProfileMsg: '',
                updateProfileMsgVisib: 'hidden',
                updateProfileMsgClass: 'text-success',
                updateAvatar: false,
                updateAvatarMsg: '',
                updateAvatarMsgVisib: 'hidden',
                updateAvatarMsgClass: 'text-success',

            },
            username: '',
            avatarFile: null,
            myImagesArr: [],
            nextPageIndex: 0,
            imgsArrHeight: window.innerHeight - (53 + 20 + 40) + 'px',
            myImagesChoosing: false,
            share: {
                modal: false,
                images: [],
                shareId: null,
                type: 'add',
                mySharesImages: [],
                mySharesPageIndex: 0,
            },
            imageDetail: {
                modal: false,
                image: {},
            },
            goShare: this.$route.query.goShare,
            goMyImages: this.$route.query.goMyImages,
        };
    },
    methods: {
        setActiveTab(tab) {
            this.activeTab = tab;
            if (this.activeTab == 'myImagesTab') {
                this.myImagesArr = [];
                this.nextPageIndex = 0;
                this.findImagesByPage();
            } else if (this.activeTab == 'mySharesTab') {
                this.share.mySharesImages = [];
                this.share.mySharesPageIndex = 0;
                this.findShareImagesByPage();
            }
        },
        async getCurrentUser() {
            var token = localStorage.getItem('ga-user-token');
            if (token == null || token.length == 0) {
                this.$router.push({ path: "/login" });
            }
            try {
                const response = await this.axios.post('/user/getCurrentUserSuccess', {});
                if (response.data.success) {
                    // Handle successful login, e.g., redirect to dashboard
                    var currentUser = response.data['data'];
                    if (currentUser == null || currentUser == undefined) {
                        this.$router.push({ path: "/login" });
                    } else {
                        this.currentUser = currentUser;
                        // console.log(currentUser);
                    }

                } else {
                    // Handle error message from server
                }
            } catch (error) {
                // Handle network or other errors
                console.error(error);
            }

        },
        triggerAvatarFile() {
            this.$refs.avatarFile.click();
        },
        handleAvatarFileChange(event) {
            const file = event.target.files[0];
            if (file.size > (1024 * 512)) {
                this.modals.updateAvatarMsg = 'The avatar must be less than 512 KB';
                this.modals.updateAvatarMsgVisib = 'visible';
                this.modals.updateAvatarMsgClass = 'text-danger';
                return;
            }
            this.avatarFile = file;
            this.modals.updateAvatarMsg = this.avatarFile.name;
            this.modals.updateAvatarMsgVisib = 'visible';
            this.modals.updateAvatarMsgClass = 'text-success';
        },
        async handleAvatarFileSubmit() {
            if (this.avatarFile) {
                // Handle file upload
                var formData = new FormData();
                formData.append('file', this.avatarFile);
                let config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                };
                try {
                    const response = await this.axios.post('/file/upload', formData, config);
                    if (response.data.success) {
                        var fileUrl = response.data['data']['url'];
                        if (fileUrl == null || fileUrl == '') {
                            this.modals.updateAvatarMsg = 'avatar upload failed';
                            this.modals.updateAvatarMsgVisib = 'visible';
                            this.modals.updateAvatarMsgClass = 'text-danger';

                        } else {
                            const response1 = await this.axios.post('/user/updateAvatar', { 'avatarUrl': fileUrl });
                            if (response1.data.success) {
                                this.modals.updateAvatarMsg = response.data.msg;
                                this.modals.updateAvatarMsgVisib = 'visible';
                                this.modals.updateAvatarMsgClass = 'text-success';
                            }

                            setTimeout(() => {
                                this.modals.updateAvatar = false;
                                window.location.reload();
                            }, 1000);
                        }

                    } else {
                        // Handle error message from server
                        this.modals.updateAvatarMsg = response.data.msg;
                        this.modals.updateAvatarMsgVisib = 'visible';
                        this.modals.updateAvatarMsgClass = 'text-danger';
                    }
                } catch (error) {
                    // Handle network or other errors
                    this.modals.updateAvatarMsg = response.data.msg;
                    this.modals.updateAvatarMsgVisib = 'visible';
                    this.modals.updateAvatarMsgClass = 'text-danger';
                }
            } else {
                this.modals.updateAvatarMsg = 'No file selected.';
                this.modals.updateAvatarMsgVisib = 'visible';
                this.modals.updateAvatarMsgClass = 'text-danger';
            }
        },
        async findImagesByPage() {
            try {
                const response = await this.axios.post('/userImages/findImagesByPage', { 'pageNum': this.nextPageIndex });
                if (response.data.success) {
                    var totalPages = response.data['data']['totalPages'];
                    var datas = response.data['data']['datas'];
                    if (datas !== null && datas.length > 0) {
                        var imgs = [];
                        datas.forEach(data => {
                            data['src'] = data['url'];
                            data['selected'] = false;
                            imgs.push(data);
                        });
                        this.myImagesArr = this.myImagesArr.concat(imgs);
                        this.nextPageIndex++;
                    } else {
                        if (this.nextPageIndex >= totalPages) {
                            this.$refs.myImagesWaterfall.waterfallOver();
                        }
                    }

                } else {
                    console.error(response.data['msg']);
                }
            } catch (error) {
                console.error(error);
            }

        },
        async findShareImagesByPage() {
            try {
                const response = await this.axios.post('/userImages/findShareImagesByPage', { 'pageNum': this.share.mySharesPageIndex, 'sortType': 'Latest' });
                if (response.data.success) {
                    var totalPages = response.data['data']['totalPages'];
                    var datas = response.data['data']['datas'];
                    if (datas !== null && datas.length > 0) {
                        var imgs = [];
                        datas.forEach(data => {
                            imgs.push({
                                'src': data['coverObjectUrl'], "id": data['id'], "favorite": data['favorite'],
                                "favoriteCount": data['favoriteCount'], "isPublish": data['isPublish'], "isPublishName": data['isPublishName']
                            });
                        });
                        this.share.mySharesImages = this.share.mySharesImages.concat(imgs);
                        this.share.mySharesPageIndex++;
                    } else {
                        if (this.share.mySharesPageIndex >= totalPages) {
                            this.$refs.mySharesWaterfall.waterfallOver();
                        }
                    }

                } else {
                    console.error(response.data['msg']);
                }
            } catch (error) {
                console.error(error);
            }

        },
        choiceImages() {
            if (this.myImagesChoosing) {
                this.myImagesChoosing = false;
            } else {
                this.myImagesChoosing = true;
            }
        },
        clickMyImage(event, { index, value }) {
            // 阻止a标签跳转
            event.preventDefault();

            if (this.myImagesChoosing) {
                if (value.selected) {
                    value.selected = false;
                } else {
                    value.selected = true;
                }
            } else {
                // 只有当点击到图片时才进行操作
                if (event.target.tagName.toLowerCase() == 'img') {
                    this.imageDetail.image = value;
                    this.imageDetail.modal = true;
                }
            }

        },
        clickMyShareImage(event, { index, value }) {
            // 阻止a标签跳转
            event.preventDefault();

            this.share.shareId = value.id;
            this.share.type = 'edit';
            this.share.modal = true;


        },
        showShareImages() {
            this.share.images = [];
            this.share.type = 'add';
            this.myImagesArr.forEach(image => {
                if (image['selected']) {
                    this.share.images.push(image);
                }
            });
            if (this.share.images.length > 0) {
                this.share.modal = true;
            } else {
                Message({
                    message: 'Please select image',
                    type: 'warning'
                });
            }

        },
        shareImagesSuccess(msg) {
            this.share.modal = false;
            this.myImagesChoosing = false;
            this.share.images.forEach(image => {
                image.selected = false;
            });
            Message({
                message: msg,
                type: 'success'
            });
            this.setActiveTab('mySharesTab');

        },
        deleteImages() {
            var imageIds = [];
            this.myImagesArr.forEach(image => {
                if (image['selected']) {
                    imageIds.push(image.id);
                }
            });
            if (imageIds.length > 0) {
                MessageBox.confirm(this.$t('myImages.deleteMessage'), 'Confirm', {
                    confirmButtonText: 'Delete',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.deleteImagesByIds(imageIds);
                });

            } else {
                Message({
                    message: this.$t('myImages.selectMessage'),
                    type: 'warning'
                });
            }

        },
        async deleteImagesByIds(imageIds) {
            try {
                const response = await this.axios.post('/userImages/deleteImagesByIds', { 'ids': imageIds });
                if (response.data.success) {
                    Message({
                        message: response.data['msg'],
                        type: 'success'
                    });
                    this.setActiveTab('myImagesTab');

                } else {
                    Message({
                        message: response.data['msg'],
                        type: 'warning'
                    });
                }
            } catch (error) {
                console.error(error);
            }
        }
    },
    created() {
        //UI渲染前执行
        this.getCurrentUser();
    },
    mounted() {
        //UI渲染后执行
        if (this.goShare) {
            this.setActiveTab('myImagesTab');
            this.myImagesChoosing = true;
        } else if (this.goMyImages) {
            this.setActiveTab('myImagesTab');
        }

    },
    destroyed() {
    }
};
</script>
  
<style scoped>
.user-profile {
    display: flex;
    height: 100vh;
    padding-top: 53px;
}

.sidebar {
    width: 250px;
    padding: 20px;
    background-color: #f7f7f7;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-info {
    text-align: center;
    margin-bottom: 20px;
}

.avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
}

.username {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
}

.nav-buttons {
    width: 100%;
}

.content {
    flex: 1;
    height: 100%;
    padding: 20px 0px 0px 20px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.details {
    display: flex;
    flex-wrap: wrap;
}

.detail-item {
    width: 50%;
    padding: 10px;
    box-sizing: border-box;
}

.detail-item strong {
    display: inline-block;
    width: 250px;
}

.detail-item-post {
    width: 10%;
    min-width: 120px;
    padding: 0px 0px 0px 0px;
    box-sizing: border-box;
    font-size: 12px;
}

.detail-item-post strong {
    display: inline-block;
    width: auto;
}

.img-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f9f9;
    border-radius: 4px;
    margin-bottom: 20px;
    position: absolute;
    left: 15px;
    bottom: 0px;
    background-color: transparent;
}

.custom-checkbox {
    margin-left: 10px;
    margin-bottom: 15px;
    transform: scale(0.8);
    /* 调整复选框的尺寸 */
}

.custom-checkbox input[type="checkbox"]:checked+.form-check-sign .check {
    background-color: #f96332;
    /* 自定义选中时的背景颜色 */
    border-color: #f96332;
    /* 自定义选中时的边框颜色 */
}

.divider {
    margin: 5px 0;
    border: none;
    border-top: 1px solid #ddd;
}
</style>
  