<template>
  <div class="image-detail">
    <div class="image-section">
      <img :src="image.src" />
    </div>
    <div class="action-buttons">
      <el-button icon="el-icon-download" circle @click="download(image.src)"></el-button>
      <!-- <el-button class="btn-neutral">275</el-button>
        <el-button class="btn-neutral">分享</el-button> -->
    </div>
    <div class="info-section">
      <h3>Prompt</h3>
      <p>{{ image.prompt }}</p>
      <h3>Negative Prompt</h3>
      <p>{{ image.negPrompt }}</p>
      <h3>infotexts</h3>
      <p style="white-space: pre-wrap;">{{ image.config['infotexts'][0] }}</p>
    </div>
    <!-- <div class="related-models">
      <h3>引用模型</h3>
      <div v-for="model in models" :key="model.name" class="model-item">
        <el-tag>{{ model.name }}</el-tag>
      </div>
    </div> -->

  </div>
</template>

<script>
import { Button, Tag } from 'element-ui';

export default {
  components: {
    [Button.name]: Button,
    [Tag.name]: Tag,
  },
  props: ['image'],
  data() {
    return {
    };
  },
  methods: {
    download(url) {
      window.open(url);
    }
  }
};
</script>

<style scoped>
.image-detail {
  max-width: 800px;
  margin: 20px auto;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.image-section img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.info-section {
  margin-top: 20px;
}

.action-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.tags-section {
  margin-top: 20px;
}

.related-models {
  margin-top: 20px;
}

.model-item {
  margin-top: 10px;
}

.comments-section {
  margin-top: 20px;
}

.comment-item {
  border-top: 1px solid #eee;
  padding-top: 10px;
  margin-top: 10px;
}

.comment-date {
  color: #999;
  font-size: 0.8em;
}
</style>
