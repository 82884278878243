module.exports = {
    nav: {
        times: "times",
        Beta: "Beta",
    },
    paint: {
        title: "AI painting",
        desc: "Leading cloud AI painting platform",
        painting: "Painting",

    },
    e_learn: {
        title: "E-learning",
        desc: "Official online learning platform of Genius academy",
    },
    chatgpt_x: {
        title: "ChatGPT-X",
        desc: "Build your own AI assistant",
    },
    signup: {
        title: "Sign up",
        UpdatePassword: "Update Password",
        Password: "Password",
        NewPassword: "New Password",
        NewPasswordAgain: "New Password Again",
        Logout: "Logout",
        Login: "Login",
        Email: "Email",
        CreateAccount: "Create Account",
        SignUp: "Sign Up",
        Next: "Next",
        VerifyCode: "Verify Code",
        GetVerifyCode: "Get Verify Code",
        PasswordAgain: "Password, Again",
        EnterPhoneNumber: "Enter phone number",
        EnterLisence: "Masukkan lisensi",
        sendVerifyCodeError: "An error occurred during sendVerifyCode. Please try again.",
        registError: "An error occurred during regist. Please try again.",
        loginError: "An error occurred during login. Please try again.",
        forgotPassword: "Forgot password?",
        ResetPassword: "Reset password",
        Reset: "Reset",
        ResetError: "An error occurred during reset password. Please try again.",
        LoginTips: "You can log in directly using your ChatGPT-X account"
    },
    button: {
        Submit: "Submit",
        GoWhatsapp: "Open Whatsapp",
    },
    redeemcode: {
        UseRedeemCode: "Use Redeem Code",
        RedeemCode: "Redeem Code",
        GetRedeemCode: "Get Redeem Code",
    },
    profile: {
        myInfo: "My info",
        myImages: "My images",
        myShares: "My shares",
        myDetails: "My details",
        userName: "User name",
        email: "Email",
        memberLevel: "Member level",
        memberExpireDate: "Member Expire date",
        likeCount: "Likes",
        favoriteCount: "Favorites",
        updateAvatar: "Modify avatar",
        uploadAvatarFile: "Select avator",
    },
    waterfall: {
        over: "It's all here"
    },
    myImages: {
        choiceShare: "Select",
        cancelChoice: "Cancel",
        share: "Share",
        delete: "Delete",
        deleteMessage: "You will delete these files, continue?",
        selectMessage: "Please select images",

    },
    imageDetail: {
        detail: "Image detail",
    },
    imageShare: {
        title: "Share title",
        desc: "Description",
        tags: "Tags",
        publish: "Publish",
        delete: "Delete",
        minPics: "Select at least one picture",
        maxPics: "Select a maximum of six images",
        deleteMessage: "You will delete this share, continue?",
        gotoShare: "Go to share",
    },
    shareDetail: {
        detail: "Share detail",
    },
    Recharge: {
        Name: "Top up",
        ModalTitle: "Top Up",
        ToWhatsapp: "Go to Whatsapp",
        RechargeTitle: {
            nowRecharge: "Now top up ",
            ToGet: " to get ",
            Package: " 200 times/day/month ",
            OriginalPrice: "original price ",
            ToBuyByWhatsapp:
                "Contact customer Service via Whatsapp to purchase redemption code: ",
        },
    },
}