<template>
  <div class="page-header clear-filter" filter-color="">
    <div class="page-header-image" style="background-image: url('img/login1.png')"></div>
    <div class="container">
      <alert v-if="showAlert" ref="alert" :type="showAlertType">
        {{ showAlertContext }}
      </alert>
      <div class="col-md-5 ml-auto mr-auto">
        <card class="card-signup" header-classes="text-center" color="orange">
          <template slot="header">
            <h3 class="card-title title-up">{{ $t('signup.SignUp') }}</h3>
            <!-- <div class="social-line">
            <a
              href="#pablo"
              class="btn btn-neutral btn-facebook btn-icon btn-round"
            >
              <i class="fab fa-facebook-square"></i>
            </a>
            <a
              href="#pablo"
              class="btn btn-neutral btn-twitter btn-icon btn-lg btn-round"
            >
              <i class="fab fa-twitter"></i>
            </a>
            <a
              href="#pablo"
              class="btn btn-neutral btn-google btn-icon btn-round"
            >
              <i class="fab fa-google-plus"></i>
            </a>
          </div> -->
          </template>
          <template>
            <form v-show="showFirstFrom" data-vv-scope="firstFrom" @submit.prevent="next()">
              <fg-input type="email" class="no-border input-lg" :style="{ margin: '0px' }"
                addon-left-icon="now-ui-icons ui-1_email-85" :placeholder="$t('signup.Email')" v-model="email"
                name="email" v-validate="'required|email'" ref="email">
              </fg-input>
              <span v-show="errors.has('firstFrom.email')" class="text-neutral">{{ errors.first('firstFrom.email')
              }}</span>
              <div class="pull-right" v-if="showFirstFrom">
                <button type="button" class="btn btn-link btn-neutral" @click="getVerifyCode"
                  :style="{ margin: '0px 1px' }">{{ timeCountText }}</button>
              </div>
              <fg-input type="text" class="no-border input-lg" addon-left-icon="now-ui-icons ui-1_lock-circle-open"
                :placeholder="$t('signup.VerifyCode')" v-model="registVerifyCode" name="registVerifyCode"
                v-validate="'required|digits:6'">
              </fg-input>
              <span v-show="errors.has('firstFrom.registVerifyCode')" class="text-neutral">{{
                errors.first('firstFrom.registVerifyCode') }}</span>
              <!-- <fg-input
              type="text"
              class="no-border input-lg"
              addon-left-icon="now-ui-icons objects_key-25"
              :placeholder="$t('signup.EnterLisence')" v-model="license" name="license" v-validate="'required|min:12'">
            </fg-input>
            <span v-show="errors.has('firstFrom.license')" class="text-neutral">{{ errors.first('firstFrom.license') }}</span> -->
              <button type="submit" class="btn btn-neutral btn-round btn-lg btn-block">{{ $t('signup.Next') }}</button>
            </form>
            <form v-show="showSecondFrom" data-vv-scope="secondFrom" @submit.prevent="checkForm()">
              <fg-input type="password" class="no-border input-lg" addon-left-icon="now-ui-icons ui-1_lock-circle-open"
                :placeholder="$t('signup.Password')" v-model="password" name="password" v-validate="'required|min:6'"
                ref="password">
              </fg-input>
              <span v-show="errors.has('secondFrom.password')" class="text-neutral">{{ errors.first('secondFrom.password')
              }}</span>
              <fg-input type="password" class="no-border input-lg" addon-left-icon="now-ui-icons ui-1_lock-circle-open"
                :placeholder="$t('signup.PasswordAgain')" v-model="password2" name="password2"
                v-validate="'required|confirmed:password'" data-vv-as="password">
              </fg-input>
              <span v-show="errors.has('secondFrom.password2')" class="text-neutral">{{
                errors.first('secondFrom.password2') }}</span>
              <fg-input type="tel" class="no-border input-lg" addon-left-icon="now-ui-icons whatsapp"
                :placeholder="$t('signup.EnterPhoneNumber')" v-model="phoneNumber" name="phoneNumber">
              </fg-input>
              <button type="submit" class="btn btn-neutral btn-round btn-lg btn-block">{{ $t('signup.SignUp') }}</button>
            </form>
            <div class="card-footer text-center" :style="{ margin: '0px 0px 0px 0px', padding: '0px 0px 24px 0px' }">
              <div class="pull-left">
                <h6>
                  <router-link :to="{ path: '/login' }" class="link footer-link">{{ $t('signup.Login') }}</router-link>
                </h6>
              </div>
            </div>

          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Button, FormGroupInput, Alert } from '@/components';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
export default {
  name: 'login-page',
  bodyClass: 'login-page',
  components: {
    Card,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    Alert
  },
  data() {
    return {
      email: '',
      password: '',
      password2: '',
      countryCode: '',
      phone: '',
      phoneNumber: '+62 ',
      phoneNumberDef: '+62 ',
      license: '',
      registVerifyCode: '',
      canGetVerifyCode: true,
      getVerifyCodeTime: 60,
      timeCountText: this.$t('signup.GetVerifyCode'),
      timeCountTextDef: this.$t('signup.GetVerifyCode'),
      showAlert: false,
      showAlertType: 'success',
      showAlertContext: '',
      showFirstFrom: true,
      showSecondFrom: false,
    };
  },
  methods: {
    getVerifyCode() {
      if (this.email.trim().length > 0 && this.canGetVerifyCode) {
        this.sendVerifyCode();
        this.countDownTime();
      }
    },
    async sendVerifyCode() {
      try {
        const response = await this.axios.post('/user/regist/generateVerifyCode', {
          email: this.email
        });
        // console.log(response);
        if (response.data.success) {
          // Handle successful login, e.g., redirect to dashboard
        } else {
          // Handle error message from server
          this.showAlertFunc('danger', response.data.msg);
        }
      } catch (error) {
        // Handle network or other errors
        // console.log(error);
        this.showAlertFunc('danger', this.$t('signup.sendVerifyCodeError'));
      }
    },
    countDownTime() {
      let totalTime = this.getVerifyCodeTime;
      this.canGetVerifyCode = false;
      this.timeCountText = totalTime;
      this.refreshData = window.setInterval(() => {
        totalTime--;
        this.timeCountText = totalTime;
        if (totalTime < 0) {
          //当倒计时小于0时清除定时器
          window.clearInterval(this.refreshData); //清除定时器
          this.timeCountText = this.timeCountTextDef;
          this.canGetVerifyCode = true; //这里重新开启
        }
      }, 1000);
    },
    next() {
      this.$validator.validateAll('firstFrom').then((isValid) => {
        if (isValid) {
          this.showFirstFrom = false;
          this.showSecondFrom = true;
        }
      });
    },
    checkForm() {
      this.$validator.validateAll('secondFrom').then((isValid) => {
        if (isValid) {
          if (this.phoneNumberDef != this.phoneNumber) {
            const phoneObj = parsePhoneNumberFromString(this.phoneNumber);
            // console.log(phoneObj);
            this.countryCode = phoneObj.countryCallingCode;
            this.phone = phoneObj.nationalNumber;
          }
          this.regist();
        }
      });
    },
    async regist() {
      try {
        const response = await this.axios.post('/user/regist', {
          email: this.email,
          password: this.password,
          password2: this.password2,
          registVerifyCode: this.registVerifyCode,
          countryCode: this.countryCode,
          phone: this.phone,
          license: this.license
        });
        if (response.data.success) {
          // Handle successful login, e.g., redirect to dashboard
          this.$router.push('/login');
        } else {
          // Handle error message from server
          this.showAlertFunc('danger', response.data.msg);
        }
      } catch (error) {
        // Handle network or other errors
        this.showAlertFunc('danger', this.$t('signup.registError'));
      }
    },
    showAlertFunc(type, content) {
      this.showAlertType = type;
      this.showAlertContext = content;
      this.showAlert = true;
      setTimeout(() => {
        this.showAlert = false;
      }, 5000);
    }
  }
};
</script>
<style>
.input-group {
  display: flex;
}

.input-group select {
  flex: 0 0 auto;
  /* Adjust the width as needed */
}

.input-group input {
  flex: 1 1 auto;
}
</style>
