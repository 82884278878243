import Vue from 'vue';
import Router from 'vue-router';
import Index from './pages/Index.vue';
import Login from './pages/Login.vue';
import Regist from './pages/Regist.vue';
import PrivacyPolicy from './pages/PrivacyPolicy.vue';
import MainNavbar from './layout/MainNavbar.vue';
import ResetPassword from './pages/ResetPassword.vue';
import UserProfile from './pages/UserProfile.vue';
import shareIndex from './pages/share/Index.vue';
import imageShare from './pages/share/ImageShare.vue';


Vue.use(Router);

export default new Router({
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'shareIndex',
      components: { default: shareIndex, header: MainNavbar },
      props: {
      }
    },
    {
      path: '/painting',
      name: 'painting',
      components: { default: Index, header: MainNavbar },
      props: {
      }
    },
    {
      path: '/login',
      name: 'login',
      components: { default: Login, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 }
      }
    },
    {
      path: '/regist',
      name: 'regist',
      components: { default: Regist, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 }
      }
    },
    {
      path: '/privacyPolicy',
      name: 'privacyPolicy',
      components: { default: PrivacyPolicy },
      props: {
      }
    },
    {
      path: '/resetPassword',
      name: 'resetPassword',
      components: { default: ResetPassword, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 }
      }
    },
    {
      path: '/userProfile',
      name: 'userProfile',
      components: { default: UserProfile, header: MainNavbar },
      props: {
        header: {}
      }
    },
    {
      path: '/share/index',
      name: 'shareIndex',
      components: { default: shareIndex, header: MainNavbar },
      props: {
        header: {}
      }
    },
    {
      path: '/share/imageShare',
      name: 'imageShare',
      components: { default: imageShare, header: MainNavbar },
      props: {
        header: {}
      }
    }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
