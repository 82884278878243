<template>
  <div>
    <div class="page-header clear-filter" :style="{ margin: '60px 0px 0px 0px' }">
      <parallax class="page-header-image" style="background-image:url('img/login1.png')">
      </parallax>
      <iframe ref="iframe_sd" @load="sdLoad" :src="sdUrl" width="100%" height="100%" frameborder="0"></iframe>
    </div>
  </div>
</template>
<script>

export default {
  name: 'index',
  bodyClass: 'index-page',
  components: {

  },
  data() {
    return {
      sdUrl: process.env.VUE_APP_SD_URL,
      currentUser: {},
      userName: '',
    };
  },
  methods: {
    sdLoad(){
      this.$refs.iframe_sd.contentWindow.postMessage(
          JSON.stringify({
            'ga-user-token': localStorage.getItem('ga-user-token'),
            'ga-user-id': localStorage.getItem('ga-user-id'),
            'ga-user-vipType': localStorage.getItem('ga-user-vipType')
          }),
          this.sdUrl
      );
    }
  },
  mounted(){
    
      
  }
};
</script>
<style></style>
