/*!

 =========================================================
 * Vue Now UI Kit - v1.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/now-ui-kit
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from 'vue';
import App from './App.vue';
// You can change this import to `import router from './starterRouter'` to quickly start development from a blank layout.
import router from './router';
import NowUiKit from './plugins/now-ui-kit';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VeeValidate from 'vee-validate';
import VueCookies from "vue-cookies";
import VueI18n from 'vue-i18n';
import './assets/fonts/font_4578915/iconfont.css';
import './assets/fonts/custom.css';

Vue.config.productionTip = false;

Vue.use(NowUiKit);
Vue.use(VueAxios, axios);
Vue.use(VeeValidate);
Vue.use(VueCookies);
Vue.use(VueI18n);



//多语言实例
let defaultLang = 'en';
let localStorageLang = localStorage.getItem('ga-lang');
let navigatorLang = navigator.language;
let gaLang = defaultLang;
if (localStorageLang != null) {
  gaLang = localStorageLang
} else if (navigatorLang != null) {
  gaLang = navigatorLang
}
let LANGUAGE_CONFIG = eval("(" + process.env.VUE_APP_LANGUAGE_CONFIG + ")");
if (gaLang.startsWith(LANGUAGE_CONFIG['en'])) {
  gaLang = 'en';
}
const i18n = new VueI18n({
  locale: gaLang,
  fallbackLocale: defaultLang,
  messages: {
    'en': require('./assets/language/en'), //英文语言包
    'id_ID': require('./assets/language/id'), //印尼语语言包
  }
});

//API 请求设置
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.headers.common['Authorization'] = localStorage.getItem('ga-user-token');
axios.defaults.headers.common['Accept-Language'] = LANGUAGE_CONFIG[gaLang] + ';q=0.9,en;q=0.5';


new Vue({
  router,
  render: h => h(App),
  i18n
}).$mount('#app');
