<template>
  <navbar position="fixed" type="primary" :transparent="transparent" :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto">
    <template>
      <router-link v-popover:popover1 class="navbar-brand" to="/">
        {{ $t('paint.title') }}
      </router-link>
      <badge type="info">{{ $t('nav.Beta') }}</badge>
      <el-popover ref="popover1" popper-class="popover" placement="bottom" width="200" trigger="hover">
        <div class="popover-body">
          {{ $t('paint.desc') }}
        </div>
      </el-popover>
    </template>
    <template slot="navbar-menu">
      <li class="nav-item">
        <router-link :to="{ path: '/painting' }" class="nav-link" rel="tooltip">
          <i class="now-ui-icons design_palette"></i>
          {{ $t('paint.painting') }}
        </router-link>
      </li>
      <li class="nav-item">
        <a class="nav-link" rel="tooltip" :title="$t('chatgpt_x.desc')" data-placement="bottom"
          href="https://chat.geniusitedu.com" target="_blank">
          <i class="icon iconfont ga-icon-robot"></i>
          {{ $t('chatgpt_x.title') }}
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" rel="tooltip" :title="$t('e_learn.desc')" data-placement="bottom"
          href="https://learn.geniusitedu.com" target="_blank">
          <i class="now-ui-icons education_atom"></i>
          {{ $t('e_learn.title') }}
        </a>
      </li>
      <li v-if="showSignUp" class="nav-item">
        <router-link :to="{ path: '/login' }" class="nav-link" rel="tooltip">
          {{ $t('signup.title') }}
        </router-link>
      </li>
      <li class="nav-item" v-if="showUserMenu">
        <badge type="warning" :style="{ margin: '10px 0px' }">{{ perResidue }} {{ $t('nav.times') }}</badge>
      </li>
      <drop-down tag="li" :title="currentLanguage" class="nav-item">
        <nav-link @click.native="changeLanguage('en')" style="cursor: pointer;">
          English
        </nav-link>
        <nav-link @click.native="changeLanguage('id_ID')" style="cursor: pointer;">
          Indonesia
        </nav-link>
      </drop-down>
      <drop-down tag="li" :title="userName" icon="now-ui-icons users_circle-08" class="nav-item" v-if="showUserMenu">
        <nav-link :to="{ path: '/userProfile' }" style="cursor: pointer;">
          <i class="now-ui-icons users_single-02"></i> Profile
        </nav-link>
        <nav-link :to="{ path: '/userProfile?goMyImages=true' }" style="cursor: pointer;">
          <i class="now-ui-icons design_image"></i> {{ $t('profile.myImages') }}
        </nav-link>
        <nav-link @click.native="modals.getRedeemcode = true" style="cursor: pointer;">
          <i class="now-ui-icons business_bulb-63"></i> {{ $t('redeemcode.GetRedeemCode') }}
        </nav-link>
        <nav-link @click.native="modals.useRedeemcode = true" style="cursor: pointer;">
          <i class="now-ui-icons business_money-coins"></i> {{ $t('redeemcode.RedeemCode') }}
        </nav-link>
        <nav-link @click.native="modals.updatepassword = true" style="cursor: pointer;">
          <i class="now-ui-icons ui-1_lock-circle-open"></i> {{ $t('signup.UpdatePassword') }}
        </nav-link>
        <nav-link @click.native="logout" style="cursor: pointer;">
          <i class="now-ui-icons media-1_button-power"></i> {{ $t('signup.Logout') }}
        </nav-link>
      </drop-down>
    </template>
    <modal :show.sync="modals.updatepassword" headerClasses="justify-content-center">
      <h4 slot="header" class="title title-up">{{ $t('signup.UpdatePassword') }}</h4>
      <!-- <form @submit.prevent="checkFormUpPass"> -->
      <fg-input type="password" class="no-border input-lg" addon-left-icon="now-ui-icons ui-1_lock-circle-open"
        :placeholder="$t('signup.Password')" v-model="password" name="password" v-validate="'required|min:6'"
        ref="password">
      </fg-input>
      <span v-show="errors.has('password')" class="text-neutral">{{ errors.first('password') }}</span>
      <fg-input type="password" class="no-border input-lg" addon-left-icon="now-ui-icons ui-1_lock-circle-open"
        :placeholder="$t('signup.NewPassword')" v-model="password1" name="password1" v-validate="'required|min:6'"
        ref="password1">
      </fg-input>
      <span v-show="errors.has('password1')" class="text-neutral">{{ errors.first('password1') }}</span>
      <fg-input type="password" class="no-border input-lg" addon-left-icon="now-ui-icons ui-1_lock-circle-open"
        :placeholder="$t('signup.NewPasswordAgain')" v-model="password2" name="password2"
        v-validate="'required|confirmed:password1'" data-vv-as="password1" ref="password2">
      </fg-input>
      <span v-show="errors.has('password2')" class="text-neutral">{{ errors.first('password2') }}</span>
      <!-- </form> -->
      <template slot="footer">
        <span :class="modals.updatePasswordMsgClass" :style="{ visibility: '{updatePasswordMsgVisib}' }">
          {{ modals.updatePasswordMsg }}
        </span>
        <n-button type="danger" @click.native="checkFormUpPass">{{ $t('button.Submit') }}</n-button>
      </template>
    </modal>
    <modal :show.sync="modals.useRedeemcode" headerClasses="justify-content-center">
      <h4 slot="header" class="title title-up">{{ $t('redeemcode.UseRedeemCode') }}</h4>
      <!-- <form @submit.prevent="checkFormUpPass"> -->
      <fg-input type="text" class="no-border input-lg" addon-left-icon="now-ui-icons business_money-coins"
        :placeholder="$t('redeemcode.RedeemCode')" v-model="redeemcode" name="redeemcode" v-validate="'required|min:6'">
      </fg-input>
      <span v-show="errors.has('redeemcode')" class="text-neutral">{{ errors.first('redeemcode') }}</span>

      <!-- </form> -->
      <template slot="footer">
        <span :class="modals.useRedeemcodeMsgClass" :style="{ visibility: '{useRedeemcodeMsgVisib}' }">
          {{ modals.useRedeemcodeMsg }}
        </span>
        <n-button type="danger" @click.native="checkFormUseRedeemCode">{{ $t('button.Submit') }}</n-button>
      </template>
    </modal>
    <modal :show.sync="modals.getRedeemcode" headerClasses="justify-content-center">
      <h4 slot="header" class="title title-up">{{ $t('redeemcode.GetRedeemCode') }}</h4>
      <div class="recharge-container">
        <img class="d-block" src="img/spesial_sale.png" />
        <h2>
          {{ $t('Recharge.RechargeTitle.nowRecharge') }}
          <span class="recharge-container-highlight">
            109,999IDR
          </span>
          {{ $t('Recharge.RechargeTitle.ToGet') }}
          {{ $t('Recharge.RechargeTitle.Package') }}
          <br />
        </h2>
        <h3 class="recharge-container-grey">
          ({{ $t('Recharge.RechargeTitle.OriginalPrice') }}
          <span class="recharge-container-striket">
            159,999IDR
          </span>
          )
        </h3>
        <p>
          {{ $t('Recharge.RechargeTitle.ToBuyByWhatsapp') }}
          <a :href="whatsappUrl" target="_blank" style="color: #007bff;">
            {{ whatsappUrl }}
          </a>
        </p>
      </div>

      <template slot="footer">
        <span></span>
        <n-button type="success" @click.native="goWhatsapp">{{
          $t('button.GoWhatsapp') }}</n-button>
      </template>
    </modal>
  </navbar>
</template>

<script>
import { DropDown, Navbar, NavLink, Button, Modal, FormGroupInput, Badge } from '@/components';
import { Image, Popover } from 'element-ui';
export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    Modal,
    DropDown,
    Navbar,
    NavLink,
    [Popover.name]: Popover,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    Badge,

  },
  data() {
    return {
      showSignUp: true,
      showUserMenu: false,
      currentUser: {},
      userName: '',
      currentLanguage: '',
      modals: {
        updatepassword: false,
        updatePasswordMsg: '',
        updatePasswordMsgVisib: 'hidden',
        updatePasswordMsgClass: 'text-success',
        useRedeemcode: false,
        useRedeemcodeMsg: '',
        useRedeemcodeMsgVisib: 'hidden',
        useRedeemcodeMsgClass: 'text-success',
        getRedeemcode: false,
        getRedeemcodeMsg: '',
        getRedeemcodeMsgVisib: 'hidden',
        getRedeemcodeMsgClass: 'text-success',
      },
      password: '',
      password1: '',
      password2: '',
      perResidue: 0,
      redeemcode: '',
      getUserErrorCount: 3,
      whatsappUrl: process.env.VUE_APP_WHATSAPP,
    };
  },
  methods: {
    async getCurrentUser() {
      var token = localStorage.getItem('ga-user-token');
      if (token == null || token.length == 0) {
        this.$router.push({ path: "/login" });
      }
      try {
        const response = await this.axios.post('/user/getCurrentUserSuccess', {});
        if (response.data.success) {
          // Handle successful login, e.g., redirect to dashboard
          var currentUser = response.data['data'];
          if (currentUser == null || currentUser == undefined) {
            if (this.getUserErrorCount >= 3) {
              this.$router.push({ path: "/login" });
            } else {
              this.getUserErrorCount++;
            }
          } else {
            this.currentUser = currentUser;
            this.userName = currentUser['name'];
            this.showSignUp = false;
            this.showUserMenu = true;
            this.getUserErrorCount = 0;
          }

        } else {
          // Handle error message from server
        }
      } catch (error) {
        // Handle network or other errors
        console.error(error);
      }

    },
    async getCurrentService() {
      var token = localStorage.getItem('ga-user-token');
      if (token == null || token.length == 0) {
        return;
      }
      try {
        const response = await this.axios.post('/user/getCurrentService', {});
        if (response.data.success) {
          // Handle successful login, e.g., redirect to dashboard
          var currentService = response.data['data'];
          if (currentService == null || currentService == undefined) {
            return;
          } else {
            this.perResidue = currentService['perResidue'];
          }

        } else {
          // Handle error message from server
        }
      } catch (error) {
        // Handle network or other errors
        console.error(error);
      }

    },
    async logout() {
      try {
        const response = await this.axios.post('/logout', {});
        if (response.data.success) {
          // Handle successful login, e.g., redirect to dashboard
          var currentUser = response.data['data'];
          if (response.data.success) {
            localStorage.removeItem('ga-user-token');
            // this.$cookies.remove('token');
            this.currentUser = {};
            this.userName = '';
            this.showSignUp = true;
            this.showUserMenu = false;
            this.$router.push({ path: "/login" });
            this.$router.go(0);
          }

        } else {
          // Handle error message from server
        }
      } catch (error) {
        // Handle network or other errors
        console.error(error);
      }
    },
    checkFormUpPass() {
      this.$validator.validateAll(['password', 'password1', 'password2']).then((isValid) => {
        if (isValid) {
          this.updatePassword();
        }
      });
    },
    async updatePassword() {
      try {
        const response = await this.axios.post('/user/updatePassword', {
          password: this.password,
          password1: this.password1,
          password2: this.password2
        });
        if (response.data.success) {
          // Handle successful login, e.g., redirect to dashboard=
          this.modals.updatePasswordMsg = response.data.msg;
          this.modals.updatePasswordMsgVisib = 'visible';
          this.modals.updatePasswordMsgClass = 'text-success';
          setTimeout(() => {
            this.modals.updatepassword = false;
          }, 1000);

        } else {
          // Handle error message from server
          this.modals.updatePasswordMsg = response.data.msg;
          this.modals.updatePasswordMsgVisib = 'visible';
          this.modals.updatePasswordMsgClass = 'text-danger';
        }
      } catch (error) {
        // Handle network or other errors
        this.modals.updatePasswordMsg = response.data.msg;
        this.modals.updatePasswordMsgVisib = 'visible';
        this.modals.updatePasswordMsgClass = 'text-danger';
      }
    },
    checkFormUseRedeemCode() {
      this.$validator.validateAll('redeemcode').then((isValid) => {
        if (isValid) {
          this.useRedeemCode();
        }
      });
    },
    async useRedeemCode() {
      try {
        const response = await this.axios.post('/service/redeemCode', {
          code: this.redeemcode,
        });
        if (response.data.success) {
          // Handle successful login, e.g., redirect to dashboard=
          this.modals.useRedeemcodeMsg = response.data.msg;
          this.modals.useRedeemcodeMsgVisib = 'visible';
          this.modals.useRedeemcodeMsgClass = 'text-success';
          setTimeout(() => {
            this.modals.useRedeemcode = false;
          }, 1000);

        } else {
          // Handle error message from server
          this.modals.useRedeemcodeMsg = response.data.msg;
          this.modals.useRedeemcodeMsgVisib = 'visible';
          this.modals.useRedeemcodeMsgClass = 'text-danger';
        }
      } catch (error) {
        // Handle network or other errors
        this.modals.useRedeemcodeMsg = response.data.msg;
        this.modals.useRedeemcodeMsgVisib = 'visible';
        this.modals.useRedeemcodeMsgClass = 'text-danger';
      }
    },
    refreshUserInfo() {
      var url = window.location.href;
      if (url.indexOf("/login") == -1
        && url.indexOf("/regist") == -1
        && url.indexOf("/resetPassword") == -1
      ) {
        this.getCurrentUser();
        this.getCurrentService();
      }
    }, changeLanguage(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem('ga-lang', lang);
      this.axios.defaults.headers.common['Accept-Language'] = this.getLanguageDesc(lang) + ';q=0.9,en;q=0.5';
      this.currentLanguage = this.getLanguageDesc(lang);
    }, getLanguageDesc(lang) {
      let LANGUAGE_CONFIG = eval("(" + process.env.VUE_APP_LANGUAGE_CONFIG + ")");
      if (lang) {
        return LANGUAGE_CONFIG[lang];
      } else {
        return LANGUAGE_CONFIG[this.$i18n.locale];
      }
    }, goWhatsapp() {
      window.open(this.whatsappUrl, "_blank");
    }
  },
  created() {
    //UI渲染前执行
    this.currentLanguage = this.getLanguageDesc();
    this.refreshUserInfo();
  },
  mounted() {
    //UI渲染后执行
    //持续更新用户状态
    var refreshUserInfoTask = window.setInterval(() => {
      this.refreshUserInfo();
    }, 30 * 1000);


  }
};
</script>

<style scoped>
.recharge-container {
  text-align: center;
  padding: 20px;
}

.recharge-container h2 {
  font-size: 2em;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}

.recharge-container p {
  margin-top: 10px;
  font-size: 1em;
}

.recharge-container a {
  color: #007bff;
  text-decoration: none;
}

.recharge-container a:hover {
  text-decoration: underline;
}

.recharge-container-highlight {
  color: #ff0000;
  /* 红色 */
  font-weight: bold;
}

.recharge-container-grey {
  color: #706f6f;
  /* 红色 */
  font-weight: bold;
}

.recharge-container-striket {
  text-decoration: line-through;
  font-style: italic;
}
</style>
